<template data-app>
  <div class="dashboard-content">
    <!-- Content -->
    <div class="card">
      <div class="filters" style="padding: 10px 10px 0px 10px">
        <h3 class="text-center">
          Usuarios<v-btn
            color="#003a56"
            dark
            class="mb-2"
            style="float: right"
            @click.prevent="handleCreate()"
            >Crear
          </v-btn>
        </h3>

        <v-row style="margin: 0px">
          <v-col cols="12" style="padding-top: 0px">
            <label>Filtrar por:</label>

            <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
              <v-col cols="12" lg="11" md="10" sm="8">
                <v-row>
                  <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
                    <v-text-field
                      dense
                      solo
                      hide-details="auto"
                      label="Búsqueda"
                      v-model="filters.search"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="6" style="padding: 1px 2px 1px 2px">
                    <multiselect
                      track-by="id"
                      label="name"
                      deselect-label=""
                      select-label=""
                      placeholder="Rol"
                      v-model="filters.role"
                      :options="roles"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="false"
                    ></multiselect>
                  </v-col>
                  <v-col cols="12" lg="3" md="3" sm="6" style="padding: 1px 2px 1px 2px">
                    <multiselect
                      track-by="id"
                      label="name"
                      deselect-label=""
                      select-label=""
                      placeholder="País"
                      :custom-label="countryName"
                      v-model="filters.countryId"
                      :options="countries"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="false"
                    ></multiselect>
                  </v-col>
                  <v-col cols="12" lg="1" md="2" sm="6" style="padding: 1px 2px 1px 2px">
                    <multiselect
                      style="min-width: 80px"
                      track-by="id"
                      label="name"
                      deselect-label=""
                      select-label=""
                      placeholder="Activo"
                      v-model="filters.active"
                      :options="activeOptions"
                      :multiple="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="false"
                    ></multiselect>
                  </v-col>
                </v-row>
              </v-col>
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="#003a56"
                style="min-width: auto; position: absolute; right: 50px; height: 25px"
                dark
                class="px-1 py-1"
                v-if="filterActivated"
                @click.prevent="
                  filterActivated = false
                  cleanFilters()
                  getUsers()
                "
              >
                <v-icon dark> mdi-delete </v-icon>
              </v-btn>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    color="#003a56"
                    style="min-width: auto; position: absolute; right: 10px; height: 25px"
                    dark
                    class="px-1 py-1"
                    @click.prevent="
                      filterActivated = true
                      getUsers()
                    "
                    ><v-icon dark> mdi-filter </v-icon>
                  </v-btn>
                </template>
                <span>Filtrar</span>
              </v-tooltip>
            </v-form>
          </v-col>
        </v-row>
      </div>
      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="loadingText"
        :hideFooter="true"
        :multiSort="false"
        :loading="loading"
        :class="'mb-4'"
        :sortable="true"
        :page="page"
        :pages="pages"
        :extraButtoms="false"
        :showCreate="true"
        :showDelete="true"
        :buttonLink="'empresas/ficha'"
        :orderOptions="orderOptions"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </div>
    <modal name="crudModal" :height="'80%'" :width="'80%'" :adaptive="true">
      <div style="height: 90%; overflow-y: scroll">
        <h3 class="text-center" v-if="flow == 'create'">Nuevo Usuario</h3>
        <h3 class="text-center" v-if="flow == 'edit'">Editar Usuario</h3>
        <div class="card" style="padding: 2rem; margin: 20px">
          <h5 style="margin-bottom: 20px">Datos generales</h5>
          <div class="row">
            <v-form ref="formCompany" v-model="valid" style="width: 100%" class="row">
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-text-field
                  required
                  :rules="descriptionRules"
                  label="Nombre"
                  v-model="name"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-text-field
                  required
                  :rules="descriptionRules"
                  label="Apellido"
                  v-model="lastname"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-text-field
                  required
                  :rules="emailRules"
                  label="Email"
                  v-model="email"
                  type="email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12" style="padding-top: 0px">
                <v-text-field
                  required
                  v-mask="phoneMask"
                  :rules="[rules.required, rules.maxNumber]"
                  label="Teléfono"
                  v-model="phone"
                  type="text"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-text-field
                  required
                  label="Usuario"
                  :rules="descriptionRules"
                  v-model="username"
                ></v-text-field>
              </v-col>
              <v-col v-if="flow == 'create'" cols="12" lg="4" md="6" sm="12">
                <v-text-field
                  label="Contraseña"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  v-model="password"
                  counter
                  @click:append="showPassword = !showPassword"
                  :type="showPassword ? 'text' : 'password'"
                ></v-text-field>
              </v-col>
              <v-col v-if="flow == 'create'" cols="12" lg="4" md="6" sm="12">
                <v-text-field
                  label="Confirmar contraseña"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  v-model="confirmPassword"
                  counter
                  @click:append="showPassword = !showPassword"
                  :type="showPassword ? 'text' : 'password'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <multiselect
                  track-by="id"
                  :rules="[(v) => !!v || 'El país es requerido']"
                  label="name"
                  placeholder="Seleccionar país"
                  v-model="selectedCountry"
                  :options="countries"
                  :multiple="false"
                  :close-on-select="true"
                  :clear-on-select="false"
                  :preserve-search="false"
                ></multiselect
              ></v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <multiselect
                  track-by="id"
                  label="name"
                  placeholder="Seleccionar roles"
                  v-model="selectedRoles"
                  :options="roles"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                ></multiselect>
              </v-col>
              <v-col cols="12" lg="4" md="6" sm="12">
                <v-checkbox label="Activar" v-model="active"></v-checkbox>
              </v-col>
            </v-form>
          </div>
        </div>
      </div>
      <v-col cols="12" style="margin-bottom: 20px; height: 10%">
        <div class="row">
          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            v-if="flow == 'create'"
            :disabled="!valid"
            color="#003a56"
            @click="createItem()"
          >
            Crear
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            v-if="flow == 'edit'"
            :disabled="!valid"
            color="#003a56"
            @click="saveItem()"
          >
            Guardar
          </v-btn>

          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            color="#003a56"
            @click="reset"
          >
            Limpiar
          </v-btn>
          <v-btn
            style="margin: auto; margin-top: 10px; color: #fff"
            color="#003a56"
            @click="$modal.hide('crudModal')"
          >
            Cancelar
          </v-btn>
        </div>
      </v-col>
    </modal>
  </div>
</template>

<script>
  const date = new Date()
  import SimpleList from 'Components/Listing/SimpleList.vue'
  import Multiselect from 'vue-multiselect'

  export default {
    name: 'Users',
    components: {
      SimpleList,
      Multiselect,
    },
    data() {
      return {
        config: {
          headers: {},
        },
        loading: false,
        headers: [
          { text: 'Nombre', value: 'name' },
          { text: 'Apellido', value: 'lastname' },
          { text: 'Email', value: 'email' },
          { text: 'Teléfono', value: 'phone' },
          { text: 'Activo', value: 'activeText' },
          { text: 'Acciones', value: 'actions', align: 'center' },
        ],
        roles: [
          { code: 'horizontal', name: 'Admin' },
          { code: 'vertical', name: 'Vertical' },
        ],
        items: [],
        users: [],
        countries: [],
        roles: [],
        selectedRoles: [],
        selectedCountries: [],
        page: 1,
        pages: 1,
        item: {},
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
          role: null,
          active: null,
          countryId: null,
        },
        phoneMask: [
          '+',
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
          /[0-9, ]/,
        ],
        activeOptions: [
          { id: null, name: 'Todas' },
          { id: 1, name: 'Activos' },
          { id: 0, name: 'Inactivos' },
        ],
        active: false,
        phone: '',
        username: '',
        password: '',
        confirmPassword: '',
        name: '',
        lastname: '',
        email: '',
        userId: '',
        countryId: '',
        order: 1,
        type: 'vertical',
        image: null,
        selectedCountry: [],
        countryText: '',
        loadingText: 'Cargando registros',
        valid: true,
        showPassword: false,
        emailRules: [
          (v) => !!v || 'E-mail es requerido',
          (v) => /.+@.+\..+/.test(v) || 'E-mail debe ser válido',
        ],
        rules: {
          required: (v) => !!v || 'Requerido.',
          min: (v) => v.length >= 8 || '6 caracteres como mínimo',
          maxNumber: (v) => v.length <= 18 || 'Debe poser menos de 18 caracteres',
        },
        descriptionRules: [
          (v) => !!v || 'La descripción es requerida',
          (v) => v.length <= 100 || 'La descripción debe poser menos de 100 caracteres',
        ],
        extraButtoms: [
          {
            text: 'Roles Asignados',
            link: 'usuarios/roles',
            icon: 'mdi-file-document-edit',
          },
        ],
      }
    },
    created() {
      this.getUsers()
      this.getRoles()
      this.getCountries()
    },
    computed: {
      likesAllCountry() {
        return this.selectedCountry.length === this.filteredCountries.length
      },
      likeSomeCountry() {
        return this.selectedCountry.length > 0 && !this.likesAllCountry
      },
      icon() {
        if (this.likesAllCountry) return 'mdi-close-box'
        if (this.likeSomeCountry) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
      filteredCountries() {
        if (this.countryText != '') {
          return this.countries.filter((country) =>
            country.name.toLowerCase().includes(this.countryText)
          )
        } else {
          return this.countries
        }
      },
      country() {
        if (localStorage.getItem('selectedCountry')) {
          return localStorage.getItem('selectedCountry')
        } else {
          return {}
        }
      },
    },
    methods: {
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getUsers()
      },
      cleanFilters() {
        this.filters = {
          search: '',
          role: null,
          active: null,
          countryId: null,
        }
      },
      countryName({ name, iso2 }) {
        if (name && iso2) {
          return `${name} (${iso2})`
        } else if (name) {
          return name
        }
      },
      handleFileUpload() {
        this.image = this.$refs.file.files[0]
      },
      toggle() {
        this.$nextTick(() => {
          if (this.likesAllCountry) {
            this.selectedCountry = []
          } else {
            this.selectedCountry = this.filteredCountries.slice()
          }
        })
      },
      async getUsers() {
        this.loading = true
        let queryParams = ''
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        if (this.filterActivated) {
          let searchString = ''
          let countryString = ''
          let roleString = ''
          let activeString = ''
          if (this.filters.search != '') {
            searchString = '&search=' + this.filters.search
          }
          if (this.filters.countryId && this.filters.countryId.id) {
            countryString = '&country_id=' + this.filters.countryId.id
          }
          if (this.filters.role && this.filters.role.name) {
            roleString = '&role=' + this.filters.role.name
          }
          if (this.filters.active && this.filters.active.id) {
            activeString = '&active=' + this.filters.active.id
          }
          queryParams = searchString + countryString + roleString + activeString
        }
        axios
          .get(
            process.env.VUE_APP_API_DIRECTORY +
              'users?page=' +
              this.page +
              orderString +
              queryParams
          )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            if (response.data.from) {
              this.page = response.data.from
              this.pages = response.data.last_page
            } else {
              this.page = 1
              this.pages = 1
            }
            this.items.map((x) => {
              let activeText
              switch (x.active) {
                case 1:
                  activeText = 'Si'
                  break
                case 0:
                  activeText = 'No'
                  break
                default:
                  activeText = ''
                  break
              }
              x.activeText = activeText
            })
            this.loading = false
          })
      },

      async getRoles() {
        this.loading = true
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'role-management/roles-list?all=1')
          .then((response) => {
            this.roles = this.roles.concat(response.data)
            this.loading = false
          })
      },

      async getCountries() {
        this.loading = true
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'countries?limit=300')
          .then((response) => {
            this.countries = this.countries.concat(response.data)
            this.selectedCountries.push(
              this.countries.filter((country) => country.iso2 == this.country.id).shift()
            )
            this.loading = false
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.name = ''
        this.lastname = ''
        this.selectedCountry = this.country.id
        this.email = ''
        this.userId = ''
        this.password = ''
        this.confirmPassword = ''
        this.phone = ''
        this.username = ''
        this.selectedRoles = []
        this.active = false

        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.name = this.item.name
        this.lastname = this.item.lastname
        this.selectedCountry = this.countries
          .filter((country) => country.id == this.item.country_id)
          .shift()
        this.email = this.item.email
        this.phone = this.item.phone
        this.username = this.item.username
        this.selectedRoles = this.item.roles
        this.active = this.item.active == 1 ? true : false

        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.form.validate()
      },

      reset() {
        this.$refs.form.reset()
      },

      async createItem() {
        this.loading = true
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + this.$session.get('tokenSession')
            : 'Bearer ',
        }

        let formData = new FormData()
        formData.append('user_id', this.userId)
        formData.append('name', this.name)
        formData.append('lastname', this.lastname)
        formData.append('type', 0)
        formData.append('email', this.email)
        formData.append('phone', this.phone)
        formData.append('username', this.username)
        formData.append('country_id', this.selectedCountry.id)
        formData.append('password', this.password)
        formData.append('password_confirmation', this.confirmPassword)
        formData.append('active', this.active ? 1 : 0)
        this.selectedRoles.forEach((role) => {
          formData.append('role[]', role.name)
        })
        axios
          .post(process.env.VUE_APP_API_DIRECTORY + 'users', formData, this.config)
          .then((response) => {
            this.page = 1
            this.getUsers()
            this.$modal.hide('crudModal')
            this.loading = false
          })
      },

      async saveItem() {
        this.loading = true
        this.active = this.active ? 1 : 0
        this.config.headers = {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: this.$session.get('tokenSession')
            ? 'Bearer ' + this.$session.get('tokenSession')
            : 'Bearer ',
        }

        let formData = new FormData()
        formData.append('name', this.name)
        formData.append('lastname', this.lastname)
        formData.append('email', this.email)
        formData.append('phone', this.phone)
        formData.append('type', 0)
        formData.append('username', this.username)
        formData.append('country_id', this.selectedCountry.id)
        formData.append('password', this.password)
        formData.append('password_confirmation', this.confirmPassword)
        formData.append('active', this.active ? 1 : 0)
        this.selectedRoles.forEach((role) => {
          formData.append('role[]', role.name)
        })
        formData.append('_method', 'patch')

        axios
          .post(
            process.env.VUE_APP_API_DIRECTORY + 'users/' + this.item.id,
            formData,
            this.config
          )
          .then((response) => {
            this.page = 1
            this.getUsers()
            this.$modal.hide('crudModal')
            this.loading = false
          })
      },

      async deleteItem(item) {
        this.loading = true
        axios
          .delete(process.env.VUE_APP_API_DIRECTORY + 'users/' + item.id)
          .then((response) => {
            this.page = 1
            this.items = []
            this.getUsers()
          })
          .catch((error) => {})
          .finally(() => (this.loading = false))
      },

      async pagination(page) {
        this.page = page
        this.items = []
      },
    },
  }
</script>
<style lang="scss">
  @import '@/assets/scss/cruds/_crudStyles.scss';
</style>
